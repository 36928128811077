import classNames from 'classnames';

import type { IconComponentType } from '@/icons';
import { Button } from '@/ui';

import type { ShareableSocialNetwork } from './types';
import { getSocialShareUrl } from './utils';

import styles from './SocialShareButton.module.scss';

interface ShareButtonProps {
    network: ShareableSocialNetwork;
    url: string;
    className?: string;
    icon: IconComponentType;
}

export function SocialShareButton({ network, url, className, icon }: ShareButtonProps) {
    const shareUrl = getSocialShareUrl(network, url);

    if (!shareUrl) {
        return null;
    }

    function handleClick() {
        window.open(shareUrl, '_blank');
    }

    return (
        <Button
            variation="secondary"
            onClick={handleClick}
            aria-label={network}
            className={classNames(styles.button, className)}
            icon={icon}
        />
    );
}
