import classNames from 'classnames';

import { IconFacebook, IconLinkedin, IconTwitter } from '@/icons';

import { SocialShareButton } from '../SocialMedia';

import StoryShareUrl from './StoryShareUrl';

import styles from './StoryLinks.module.scss';

interface Props {
    url: string | null;
    className?: string;
    buttonClassName?: string;
}

function StoryLinks({ url, buttonClassName, className }: Props) {
    if (!url) {
        return null;
    }

    return (
        <div className={classNames(styles.container, className)}>
            <SocialShareButton
                network="facebook"
                url={url}
                className={classNames(styles.button, buttonClassName)}
                icon={IconFacebook}
            />
            <SocialShareButton
                network="twitter"
                url={url}
                className={classNames(styles.button, buttonClassName)}
                icon={IconTwitter}
            />
            <SocialShareButton
                network="linkedin"
                url={url}
                className={classNames(styles.button, buttonClassName)}
                icon={IconLinkedin}
            />
            <StoryShareUrl url={url} buttonClassName={classNames(styles.button, buttonClassName)} />
        </div>
    );
}

export default StoryLinks;
