import classNames from 'classnames';
import Link from 'next/link';

import type { StoryWithImage } from 'types';

import CategoriesList from '../CategoriesList';
import StoryImage from '../StoryImage';
import StoryPublicationDate from '../StoryPublicationDate';

import styles from './StoryCard.module.scss';

type Props = {
    story: StoryWithImage;
    size?: 'small' | 'medium' | 'big';
};

function StoryCard({ story, size = 'small' }: Props) {
    const { categories, title } = story;

    const HeadingTag = size === 'small' ? 'h3' : 'h2';

    return (
        <div
            className={classNames(styles.container, {
                [styles.small]: size === 'small',
                [styles.medium]: size === 'medium',
                [styles.big]: size === 'big',
            })}
        >
            <Link href={`/${story.slug}`} locale={false} className={styles.imageWrapper}>
                <StoryImage
                    story={story}
                    className={styles.image}
                    placeholderClassName={styles.placeholder}
                />
            </Link>
            <div className={styles.content}>
                {categories.length > 0 && (
                    <div className={styles.categories}>
                        <CategoriesList
                            categories={categories}
                            showAllCategories={size !== 'small'}
                            isStatic
                        />
                    </div>
                )}

                <p className={styles.date}>
                    <StoryPublicationDate story={story} />
                </p>

                <Link href={`/${story.slug}`} locale={false}>
                    <HeadingTag
                        className={classNames(styles.title, {
                            [styles.titleSmaller]: size === 'small',
                            [styles.titleLarger]: size === 'big',
                        })}
                    >
                        <span className={styles.titleLink}>{title}</span>
                    </HeadingTag>
                </Link>
            </div>
        </div>
    );
}

export default StoryCard;
