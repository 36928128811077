import classNames from 'classnames';
import Link from 'next/link';

import { useThemeSettings } from '@/hooks';
import type { StoryWithImage } from 'types';

// import CategoriesList from '../CategoriesList';
import StoryImage from '../StoryImage';
import StoryPublicationDate from '../StoryPublicationDate';

import styles from './HighlightedStoryCard.module.scss';

type Props = {
    story: StoryWithImage;
};

const HUGE_TITLE_CHARACTERS_COUNT = 140;
const ENORMOUS_TITLE_CHARACTERS_COUNT = 240;

function HighlightedStoryCard({ story }: Props) {
    const { categories, title, subtitle } = story;
    const { showDate } = useThemeSettings();

    const isHugeTitle = title.length > HUGE_TITLE_CHARACTERS_COUNT;
    const isEnormousTitle = title.length > ENORMOUS_TITLE_CHARACTERS_COUNT;

    return (
        <Link href={`/${story.slug}`} locale={false} className={styles.container}>
            <div className={styles.imageWrapper}>
                <StoryImage
                    story={story}
                    className={styles.image}
                    placeholderClassName={styles.placeholder}
                />
            </div>
            <div className={styles.content}>
                <div className={styles.dateAndCategory}>
                    <span className={styles.date}>
                        <StoryPublicationDate story={story} />
                    </span>
                    {categories.length > 0 && showDate && (
                        <span className={styles.separator}>&middot;</span>
                    )}
                    {/* TODO: If categories appear in the newsroom, the links won't work properly because of nesting.
                    In this case the component structure will have to be reorganized to remove nested links. */}
                    {/* <CategoriesList categories={categories} /> */}
                </div>

                <h2
                    className={classNames(styles.title, {
                        [styles.huge]: isHugeTitle,
                    })}
                >
                    {title}
                </h2>

                {subtitle && (
                    <p
                        className={classNames(styles.subtitle, {
                            [styles.limited]: isHugeTitle,
                            [styles.hidden]: isEnormousTitle,
                        })}
                    >
                        {subtitle}
                    </p>
                )}
            </div>
        </Link>
    );
}

export default HighlightedStoryCard;
